import { defineStore } from 'pinia';

export const useUtilityStore = defineStore('utility', {
    state() {
        return {

        };
    },
    actions: {
        groupResourcesBy(xs, key, rt) {
            if (xs.reduce) {
                return xs.reduce(function (rv, x) {
                    (rv[rt(x[key])] = rv[rt(x[key])] || []).push(x);
                    return rv;
                }, {});
            }
            else return [];
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
    },
});